import React, { useState } from 'react';

function App() {
  const [message, setMessage] = useState(''); // To store the fetched message

  const fetchMe = async () => {
    try {
      const response = await fetch('/me');
      if (response.ok) {
        const data = await response.text();
        setMessage(data);
      } else {
        setMessage('Error fetching data');
      }
    } catch (error) {
      setMessage('Error fetching data');
    }
  };

  return (
    <div>
      <button onClick={fetchMe}>Fetch /me</button>
      <p>{message}</p>
    </div>
  );
}

export default App;
